export const isStrongPassword = (password: string): boolean => {
  const passwordLength = password.length;
  const isPasswordUppercase = /[A-Z]/.test(password);
  const isPasswordNumeric = /\d/.test(password);

  return !(passwordLength < 8 || !isPasswordUppercase || !isPasswordNumeric);
};

export const TIMEZONES = [
  { label: "(GMT-12:00) International Date Line West", value: -12 },
  { label: "(GMT-11:00) Midway Island, Samoa", value: -11 },
  { label: "(GMT-10:00) Hawaii", value: -10 },
  { label: "(GMT-09:00) Alaska", value: -9 },
  { label: "(GMT-08:00) Pacific Time (US & Canada)", value: -8 },
  { label: "(GMT-07:00) Arizona", value: -7 },
  { label: "(GMT-06:00) Central Time (US & Canada)", value: -6 },
  { label: "(GMT-05:00) Eastern Time (US & Canada)", value: -5 },
  { label: "(GMT-04:00) Caracas, La Paz", value: -4 },
  { label: "(GMT-03:30) Newfoundland", value: -3.5 },
  { label: "(GMT-03:00) Brasilia", value: -3 },
  { label: "(GMT-02:00) Mid-Atlantic", value: -2 },
  { label: "(GMT-01:00) Azores", value: -1 },
  {
    label: "(GMT+00:00) Dublin, Edinburgh, Lisbon, London",
    value: 0,
  },
  {
    label:
      "(GMT+01:00) Amsterdam, Berlin, Brussels, Copenhagen, Madrid, Paris, Rome",
    value: 1,
  },
  {
    label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    value: 2,
  },
  { label: "(GMT+03:00) Nairobi", value: 3 },
  { label: "(GMT+03:30) Tehran", value: 3.5 },
  { label: "(GMT+04:00) Abu Dhabi, Muscat", value: 4 },
  { label: "(GMT+04:30) Kabul", value: 4.5 },
  { label: "(GMT+05:00) Islamabad, Karachi, Tashkent", value: 5 },
  { label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", value: 5.5 },
  { label: "(GMT+05:45) Kathmandu", value: 5.75 },
  { label: "(GMT+06:00) Astana, Dhaka", value: 6 },
  { label: "(GMT+06:30) Yangon (Rangoon)", value: 6.5 },
  { label: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: 7 },
  { label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi", value: 8 },
  { label: "(GMT+09:00) Seoul", value: 9 },
  { label: "(GMT+09:30) Adelaide", value: 9.5 },
  { label: "(GMT+10:00) Brisbane", value: 10 },
  { label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia", value: 11 },
  { label: "(GMT+12:00) Auckland, Wellington", value: 12 },
  { label: "(GMT+13:00) Nuku'alofa", value: 13 },
];

export const isTextBlack = (backgroundcolor: string): boolean => {
  const colors = hexToRgb(backgroundcolor);

  if (!colors) {
    throw new Error("Invalid color");
  }

  const components = ["r", "g", "b"];
  for (const i in components) {
    const c = components[i];

    colors[c] = colors[c] / 255;

    colors[c] = colors[c] <= 0.03928 ? colors[c] / 12.92 : Math.pow((colors[c] + 0.055) / 1.055, 2.4);
  }

  const luminence = 0.2126 * colors.r + 0.7152 * colors.g + 0.0722 * colors.b;

  return luminence > 0.179;
};

const hexToRgb = (hex: string): Record<string, number> | null => {
  const result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex);
  return result
    ? {
        r: Number.parseInt(result[1], 16),
        g: Number.parseInt(result[2], 16),
        b: Number.parseInt(result[3], 16),
      }
    : null;
};
