import type { SubscriptionDiscount, SubscriptionItem } from "@types";

import { formatCurrency } from "./format";

export const getTitle = (
  item: SubscriptionItem,
  subscriptionTranslation: string,
): string =>
  item.recurring
    ? `${item.quantity} x ${subscriptionTranslation}`
    : `${item.quantity} x ${item.name}`;

export const getDiscount = (
  discount: SubscriptionDiscount,
  currency: SubscriptionItem["currency"],
): string =>
  discount.amountOff
    ? formatCurrency(discount.amountOff, currency)
    : `${discount.percentOff}%`;
