const AppMenuValues = [
  "app",
  "map-trackers",
  "map-history",
  "subscription",
  "checkout",
  "checkout-success",
] as const;
const RootMenuValues = [
  "home",
  "signIn",
  "signUp",
  "signOut",
  "termsConditions",
  "privacyPolicy",
  "contact",
  "unsubscribe",
] as const;
export const MenuValues = [...RootMenuValues, ...AppMenuValues] as const;

export type AppMenu = (typeof AppMenuValues)[number];
export type RootMenu = (typeof RootMenuValues)[number];
export type Menu = AppMenu | RootMenu;

export const isAppMenu = (menu: Menu): menu is AppMenu =>
  AppMenuValues.includes(menu as AppMenu);
