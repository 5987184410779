import { isAppMenu, type Menu } from "@types";

import { hostname } from "./metadata";

export const getMenuURL = (menu: Menu): string => {
  if (isAppMenu(menu)) {
    switch (menu) {
      case "app": {
        return "/app/map";
      }
      case "map-trackers": {
        return "/app/map/trackers";
      }
      case "map-history": {
        return "/app/map/history";
      }
      case "subscription": {
        return "/app/subscription";
      }
      case "checkout": {
        return "/app/checkout";
      }
      case "checkout-success": {
        return "/app/checkout/success";
      }
      default: {
        return `/app/${menu}`;
      }
    }
  } else {
    switch (menu) {
      case "home": {
        return "/";
      }
      case "signIn": {
        return "/sign-in";
      }
      case "signUp": {
        return "/sign-up";
      }
      case "signOut": {
        return "/sign-out";
      }
      case "contact": {
        return "/contact";
      }
      case "unsubscribe": {
        return "/unsubscribe";
      }
      case "termsConditions": {
        return "/terms-conditions";
      }
      case "privacyPolicy": {
        return "/privacy-policy";
      }
      default: {
        return `/${menu}`;
      }
    }
  }
};

export const getUrl = (menu: Menu, locale?: string, path?: string): string =>
  `${hostname}${locale ? `/${locale}` : ""}${getMenuURL(menu)}${path ? `/${path}` : ""}`;

export const getCallbackUrl = (pathname: string | null): string | null => {
  const callbackUrl = new URL(pathname || "").searchParams.get("callbackUrl");
  return callbackUrl
    ? `/${callbackUrl.split("/").slice(2).join("/")}`
    : callbackUrl;
};
