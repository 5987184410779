import { BadgeColorType } from "@components/badge-color";
import type {
  MapCoordinates,
  TrackerStatus,
  TrackerType,
  TrackingHistory,
  Trackings,
} from "@types";

export const trackerTypes = ["car", "truck"] as const;

export const trackerCarMarkers = [
  "car1",
  "car2",
  "car3",
  "car4",
  "car5",
  "car6",
] as const;

export const trackerTruckMarkers = [
  "truck1",
  "truck2",
  "truck3",
  "truck4",
  "truck5",
  "truck6",
  "truck7",
] as const;

export const trackerMarkers = [
  ...trackerCarMarkers,
  ...trackerTruckMarkers,
] as const;

export const TRACKER_MARKER_COLORS = [
  "#1492ff",
  "#66c2a5",
  "#abdda4",
  "#e6f598",
  "#fee08b",
  "#fdae61",
  "#f46d43",
  "#d53e4f",
  "#9e0142",
  "#5e4fa2",
];

export const getCurrentTrackersHistory = (
  trackings: Trackings,
): TrackingHistory | undefined => {
  if (trackings && trackings.length > 0) {
    return trackings.find((tracking) =>
      getCurrentTrackerHistory(tracking.history),
    )?.history[0];
  }
  return undefined;
};

export const getCurrentTrackerHistory = (
  history: TrackingHistory[],
): TrackingHistory | undefined =>
  history && history.length > 0 ? history[0] : undefined;

export const isTrackerActive = (date: number): boolean =>
  Date.now() - new Date(date).getTime() <= 5 * 60 * 1000;

export const isSpeedOverLimit = (speed: number): boolean => speed > 120;

export const getCoordinatesDistance = (
  coord1: MapCoordinates,
  coord2: MapCoordinates,
): number => {
  const r = 6371;
  const p = Math.PI / 180;

  const a =
    0.5 -
    Math.cos((coord2[0] - coord1[0]) * p) / 2 +
    (Math.cos(coord1[0] * p) *
      Math.cos(coord2[0] * p) *
      (1 - Math.cos((coord2[1] - coord1[1]) * p))) /
      2;

  return 2 * r * Math.asin(Math.sqrt(a)) * 1000;
};

export const getTrackerTypeImageURL = (type: TrackerType): string => {
  switch (type) {
    case "car": {
      return "/images/vehicles/car.png";
    }
    case "truck": {
      return "/images/vehicles/truck.png";
    }
    default: {
      return "/images/vehicles/car.png";
    }
  }
};

export const getTrackerStatusColor = (
  status: TrackerStatus,
): BadgeColorType => {
  switch (status) {
    case "active": {
      return "green";
    }
    case "ordered": {
      return "cyan";
    }
    case "orderShipped": {
      return "indigo";
    }
    case "orderDelivered": {
      return "violet";
    }
    case "inactive": {
      return "stale";
    }
    default: {
      return "stale";
    }
  }
};
