import { Metadata } from "next";

import type { Locale } from "@types";

export const hostname = "https://retraker.com";

export const getGlobalMetadata = ({
  hostname,
  locale,
  title,
  description,
}: {
  hostname: string;
  locale: Locale;
  title: string;
  description: string;
}): Metadata => ({
  title: title,
  description: description,
  metadataBase: new URL(hostname),
  openGraph: {
    siteName: title,
    type: "website",
    locale: locale,
  },
  applicationName: title,
  appleWebApp: {
    title: title,
    statusBarStyle: "default",
    capable: true,
  },
  // verification: {
  //   google: "YOUR_DATA",
  //   yandex: ["YOUR_DATA"],
  //   other: {
  //     "msvalidate.01": ["YOUR_DATA"],
  //     "facebook-domain-verification": ["YOUR_DATA"],
  //   },
  // },
  icons: {
    icon: [
      {
        url: "/images/icon/favicon.ico",
        type: "image/x-icon",
      },
      {
        url: "/images/icon/favicon-16x16.png",
        sizes: "16x16",
        type: "image/png",
      },
      {
        url: "/images/icon/favicon-32x32.png",
        sizes: "32x32",
        type: "image/png",
      },
      {
        url: "/images/icon/android-chrome-192x192.png",
        sizes: "192x192",
        type: "image/png",
      },
      {
        url: "/images/icon/android-chrome-512x512.png",
        sizes: "512x512",
        type: "image/png",
      },
    ],
    shortcut: [
      {
        url: "/images/icon/favicon.ico",
        type: "image/x-icon",
      },
    ],
    apple: [
      {
        url: "/images/icon/apple-touch-icon.png",
        sizes: "180x180",
        type: "image/png",
      },
    ],
  },
});

export const getPageMetadata = ({
  hostname,
  url,
  title,
  description,
}: {
  hostname: string;
  url: string;
  title: string;
  description: string;
}): Metadata => ({
  title: title,
  description: description,
  keywords: [
    "Vehicle Tracking",
    "Fleet Management",
    "Real-Time Vehicle Tracking",
    "Vehicle Monitoring",
    "Car Locator",
    "Route Optimization",
    "Real-Time Location Tracking",
    "Anti-theft Vehicle Tracking",
  ],
  robots: {
    index: true,
    follow: true,
    "max-image-preview": "large",
    "max-snippet": -1,
    "max-video-preview": -1,
    googleBot: "index, follow",
  },
  openGraph: {
    url: new URL(url),
    type: "website",
    title: title,
    description: description,
    // images: [
    //   {
    //     url: "https://dminhvu.com/images/home/thumbnail.png",
    //     width: 1200,
    //     height: 630,
    //     alt: "dminhvu",
    //   },
    // ],
  },
  twitter: {
    card: "summary_large_image",
    title: title,
    description: description,
    creator: "Retraker",
    site: hostname,
    // images: [
    //   {
    //     url: "https://dminhvu.com/images/home/thumbnail.png",
    //     width: 1200,
    //     height: 630,
    //     alt: "dminhvu",
    //   },
    // ],
  },
  alternates: {
    canonical: new URL(url),
  },
});

export const getPageJsonLd = ({
  url,
  locale,
  title,
  description,
}: {
  url: string;
  locale: Locale;
  title: string;
  description: string;
}): object => ({
  "@context": "https://schema.org",
  "@type": "WebPage",
  name: title,
  description: description,
  url: url,
  publisher: {
    "@type": "Organization",
    name: "Retraker",
  },
  inLanguage: locale,
  isFamilyFriendly: "true",
});
